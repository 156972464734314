import apiCall from 'app/libs/utils/api/apiCall';
import { PurchasedShipmentsResponse } from './types';
import { SharedErrors } from 'libs/utils/api/types';

// orders/:order_number/purchased_shipments/
export const purchasedShipmentsScope = (orderNumber, path?) =>
  `/orders/${orderNumber}/purchased_shipments${path || ''}`;

// orders/:order_number/purchased_shipments
export const getPurchasedShipments = orderNumber => {
  const url = purchasedShipmentsScope(orderNumber, '');
  return apiCall.get<PurchasedShipmentsResponse, SharedErrors>({ url });
};
